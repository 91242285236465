<template>
  <v-container>
    <v-row class="mb-5 text-center justify-center">
      <v-col class="d-flex" cols="12" md="3">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card link v-bind="attrs" v-on="on" outlined>
              <v-card-title>Partenaires acceptés</v-card-title>
              <v-card-text
                ><v-icon size="48" color="EoleGreen">mdi-check-decagram</v-icon>
                <h2>0</h2></v-card-text
              >
            </v-card>
          </template>
          <span
            >Les partenaires acceptés sont ceux qui apparaîtrons dans la liste générale des partenaires possible à
            ajouter au configurateur des cabinets.
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="d-flex" cols="12" md="3">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card link v-bind="attrs" v-on="on" outlined>
              <v-card-title>Partenaires en attente</v-card-title>
              <v-card-text
                ><v-icon size="48" color="EoleYellow">mdi-minus-thick</v-icon>
                <h2>{{ itemsPartnersApprovedDisapproved.length }}</h2></v-card-text
              >
            </v-card>
          </template>
          <span
            >Les partenaires en attente sont ceux pour qui les cabinets ont effectués une demande d'approbation et qui
            sont en attente de décision finale. <br />
            Cependant il reste disponible pour le cabinet ayant effectué la demande d'approbation.
          </span>
        </v-tooltip>
      </v-col>
      <v-col class="d-flex" cols="12" md="3">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-card link v-bind="attrs" v-on="on" outlined>
              <v-card-title>Partenaires refusés</v-card-title>
              <v-card-text
                ><v-icon size="48" color="EoleError">mdi-close-circle</v-icon>
                <h2>0</h2></v-card-text
              >
            </v-card>
          </template>
          <span
            >Les partenaires refusés n'apparaîtrons pas dans la liste générale des partenaires possible à ajouter au
            configurateur des cabinets. <br />
            Cependant il reste disponible pour le cabinet ayant effectué la demande d'approbation.
          </span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-data-table
      class="elevation-1"
      item-key="id"
      :loading="loading"
      loading-text="Chargement des produits en cours... Merci de patienter"
      :page.sync="page"
      :items-per-page="10"
      :headers="headers"
      :items="itemsPartnersApprovedDisapproved"
      hide-default-footer
      @page-count="pageCount = $event"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      show-expand
      :no-results-text="`Aucune donnée trouvée ayant le mot clé ${this.search}`"
      sort-by="calories"
      :footer-props="{
        'items-per-page-text': 'produits par page(s)',
      }"
      :search="search"
      :custom-filter="filterOnlyCapsText"
    >
      <template v-slot:top>
        <v-dialog v-model="dialogAddCompanyToProduct" persistent max-width="800px">
          <v-card>
            <v-card-title>
              <span class="text-h5"> <v-icon>mdi-pencil</v-icon> Modification du produit</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-divider class="mb-5"></v-divider>
                <v-alert type="info" dense color="EoleBlue" prominent text
                  >En selectionnant une compagnie ci-dessous vous pouvez l'ajouter à la liste des compagnies proposant
                  le produit {{ editedItem.name }}</v-alert
                >
                <v-row>
                  <v-col cols="12" md="12">
                    <v-select
                      :label="`Compagnie à ajouter à la liste du produit ${editedItem.name}`"
                      v-model="companyToAdd"
                      outlined
                      :items="companyAvailable"
                      item-text="name"
                      item-value="id"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="pa-5" color="warning" text @click="close">Annuler</v-btn>
              <v-btn
                class="white--text pa-5"
                color="EoleBlue"
                @click="
                  postProductLinked({
                    productName: editedItem.name,
                    idProduct: editedItem.id,
                    idInsurer: companyToAdd,
                  })
                "
              >
                <v-icon left> mdi-pencil </v-icon>Ajouter la compagnie au produit {{ editedItem.name }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5"> <v-icon>mdi-delete</v-icon> Supression du produit</span>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <v-container>
                <h3>Êtes-vous sûr de vouloir supprimer le produit : {{ editedItem.name }} ?</h3>
                <p>
                  En supprimant ce produit, il ne sera plus proposé lors de la conception de la Lettre de Devoir de
                  Conseil (LDDC)
                </p>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="EoleYellow" text @click="closeDelete">Annuler</v-btn>
              <v-btn class="white--text" color="EoleError" @click="deleteItemConfirm">Confirmer la suppression</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              large
              v-bind="attrs"
              v-on="on"
              @click="approveInsurer(item.idInsurer, 1)"
              text
              icon
              color="EoleGreen"
            >
              <v-icon> mdi-check-decagram </v-icon>
            </v-btn>
          </template>
          <span>Approuver la demande pour {{ item.name }}</span>
        </v-tooltip>

        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              large
              v-bind="attrs"
              v-on="on"
              @click="approveInsurer(item.idInsurer, 2)"
              text
              icon
              color="EoleError"
            >
              <v-icon>mdi-close-circle</v-icon>
            </v-btn>
          </template>
          <span>Refuser la demande pour {{ item.name }}</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-alert color="error" text class="ma-2">
          <div class="d-flex align-start">
            <v-icon color="error"> mdi-alert-circle-outline </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                Impossible d'accéder aux données. Merci de réessayer ultérieurement.
              </p>
            </div>
          </div>
        </v-alert>
        <v-btn color="primary" class="mb-2" @click="fetchInsurersByProduct()">Réessayer</v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td class="pa-5" :colspan="headers.length">
          <h2>Informations à propos de {{ item.name }}</h2>

          <v-row class="mt-2">
            <v-col cols="12" md="3">
              <v-text-field
                id="identity"
                outlined
                label="Identité de la société"
                readonly
                prepend-inner-icon="mdi-account"
                :value="item.name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                id="identity"
                outlined
                label="Code postale du siège"
                readonly
                prepend-inner-icon="mdi-city"
                :value="item.zip"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                id="identity"
                outlined
                label="Adresse du siège"
                readonly
                prepend-inner-icon="mdi-city"
                :value="item.street"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                id="identity"
                outlined
                label="Ville du siège"
                readonly
                prepend-inner-icon="mdi-city"
                :value="item.city"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="my-5"></v-divider>
          <v-row class="mb-2" justify="end">
            <v-btn :href="item.url" target="_blank" color="EoleBlue" class="white--text ma-2"
              >Consulter le site internet <v-icon right>mdi-search-web</v-icon></v-btn
            >
            <v-btn
              :href="`https://www.pappers.fr/recherche?q=${item.siret}`"
              target="_blank"
              color="EoleYellow"
              class="white--text ma-2"
              >Consulter les données pappers <v-icon right>mdi-open-in-new</v-icon></v-btn
            >
          </v-row>
        </td>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="pageCount"></v-pagination>
    </div>
  </v-container>
</template>

<script>
import Vue from "vue"
import Toast from "vue-toastification"
// Import the CSS or use your own!
import "vue-toastification/dist/index.css"
import config from "@/views/config/config"

Vue.use(Toast, { maxToasts: 2, newestOnTop: true })
export default {
  data: () => ({
    itemsPartnersApprovedDisapproved: [],
    dialogAddCompanyToProduct: null,
    status: [
      {
        id: 1,
        name: "Particulier",
      },
      {
        id: 2,
        name: "Professionnel",
      },
    ],
    clientTypology: {
      id: 1,
      name: "Particulier",
    },
    companyAvailable: [],
    companyToAdd: null,
    search: "",
    expanded: [],
    singleExpand: true,
    page: 1,
    pageCount: 0,
    loading: false,
    insurers: [],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "Identité de la société",
        align: "start",
        sortable: true,
        value: "name",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    products: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      id: null,
      insurersNames: [],
      clientTypology: null,
      idClientTypology: 1,
    },
    defaultItem: {
      name: "",
      id: null,
      insurersNames: [],
      clientTypology: null,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nouveau produit" : "Modification compagnie"
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  async created() {
    this.initialize()
    this.fetchPartnersDisapproved()
    this.$toast.success("Les données ont correctement été récupérées.", {
      position: "bottom-right",
      timeout: 3000,
    })
  },

  methods: {
    async approveInsurer(idInsurer, state) {
      let headers = new Headers()

      headers.append("Content-Type", "application/json charset=UTF-8")
      headers.append("Accept", "application/json")
      headers.append("Origin", "*")
      try {
        let response = await fetch(
          `${config.apiUri}/php/pages/insurers.php?idInsurer=${idInsurer}&changeValidityState=true&state=${state}`,
          {
            mode: "cors",
            method: "PUT",
            headers: headers,
          },
        )

        if (state === 1) {
          this.$toast.success("La compagnie a été approuvée.", {
            position: "bottom-right",
            timeout: 3000,
          })
        } else {
          this.$toast.success("La compagnie a été désapprouvée.", {
            position: "bottom-right",
            timeout: 3000,
          })
        }

        this.fetchPartnersDisapproved()
      } catch (e) {
        console.log(e)
        this.$toast.success(`Impossible d'approuver la demande.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async fetchPartnersDisapproved() {
      this.itemsPartnersApprovedDisapproved = []
      this.loading = true
      this.waitTimeout = true

      try {
        let headers = new Headers()
        let agencyId = this.$store.state.user.agency_id

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response = await fetch(`${config.apiUri}/partners/${agencyId}/disapproved`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })

        const data = await response.json()
        console.log(data)
        data.partners.forEach(element => {
          this.itemsPartnersApprovedDisapproved.push({
            id: element.partnerId,
            name: element.name,
            url: element.website_url,
            city: element.city,
            street: element.street,
            zip: element.zip,
            idInsurer: element.insurerId,
            siret: element.siret,
            valid: element.valid,
          })
        })
        console.log(data)

        this.loading = false
        this.fetch = true
        this.waitTimeout = false

        // this.$toast.success(
        //   `Les données ont correctement été récupérées.\nNous avons trouvé ${data.length} comptes utilisateurs lié à votre profil`,
        //   {
        //     position: "bottom-right",
        //     timeout: 3000,
        //   },
        // )
      } catch (e) {
        console.log(e)
        this.loading = false
        this.fetch = false
        this.$toast.error("Impossible d'accéder aux données lié aux comptes.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    async deleteProduct(idProduct) {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        let response
        response = await fetch(`${config.apiUri}/php/pages/products.php?idProduct=${idProduct}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        })

        this.$toast.success(`Vous avez supprimé le produit ${this.editedItem.name}`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible de supprimer le produit ${this.editedItem.name}`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async post(item) {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(
          `${config.apiUri}/php/pages/products.php?productName=${item.name}&clientTypology=${item.idClientTypology}`,
          {
            mode: "cors",
            method: "POST",
            headers: headers,
            body: JSON.stringify(item),
          },
        )
        this.close()
        this.$toast.success(`Vous avez ajouté un nouveau produit`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter une nouvelle compagnie à ce produit.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    deleteInsurerForProduct(idInsurer, idProduct) {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        fetch(`${config.apiUri}/php/pages/products.php?idProduct=${idProduct}&idInsurer=${idInsurer}`, {
          mode: "cors",
          method: "DELETE",
          headers: headers,
        })
          .then(res => res.json()) // or res.json()
          .then(() => {
            this.$toast.info(`La compagnie à bien été supprimée du produit.`, {
              position: "bottom-right",
              timeout: 3000,
            })
          })

        this.close()

        this.initialize()
        this.fetchInsurersByProduct()
      } catch (e) {
        this.$toast.error(`Une erreur est survenue.\nImpossible de supprimer le compte`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async postProductLinked(data) {
      try {
        let existInArray = false
        let headers = new Headers()

        headers.append("Content-Type", "application/json charset=UTF-8")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        this.editedItem.insurersNames.forEach(element => {
          if (element.id === this.companyToAdd) {
            existInArray = true
          }
        })

        if (!existInArray) {
          const response = await fetch(
            `${config.apiUri}/php/pages/products.php?idProduct=${data.idProduct}&idInsurer=${data.idInsurer}`,
            {
              mode: "cors",
              method: "POST",
              headers: headers,
              body: JSON.stringify(data),
            },
          )
          this.initialize()
          this.fetchInsurersByProduct()
          this.close()
          this.$toast.success(`Vous avez ajouté une nouvelle compagnie au produit ${data.productName}`, {
            position: "bottom-right",
            timeout: 3000,
          })
        } else {
          this.$toast.error(`La compagnie que vous souhaitez ajouter propose déjà ce produit.`, {
            position: "bottom-right",
            timeout: 3000,
          })
        }
      } catch (e) {
        console.log(e)
        this.$toast.error(`Une erreur est survenue.\nImpossible d'ajouter une nouvelle compagnie à ce produit.`, {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async fetchInsurers() {
      this.loading = true
      let agencyId = this.$store.state.user.agency_id
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/partners/${agencyId}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        data.partners.forEach(element => {
          this.insurers.push({
            id: element.insurerId,
            name: element.name,
          })
        })
        this.loading = false
        this.fetch = true
      } catch (e) {
        console.error(e)
        this.loading = false
        this.fetch = false
        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    filterOnlyCapsText(value, search, item) {
      return value != null && search != null && typeof value === "string" && value.toString().indexOf(search) !== -1
    },
    wip() {
      alert("Work in progress. This button don't work currently")
    },
    async fetchInsurersByProduct() {
      this.loading = true

      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/products`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()

        let x = 0
        data.forEach(element => {
          this.products.push({
            name: element.name,
            id: element.id,
            insurersNames: [],
            clientTypology: element.designation,
          })
          this.fetchProductsById(element.id, x)
          x++
        })

        this.loading = false
        this.fetch = true
      } catch (e) {
        console.log(e)
        this.loading = false
        this.fetch = false
        this.$toast.error("Impossible d'accéder aux données.\nMerci de réessayer ultérieurement.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    async fetchProductsById(idProduct, indexProduct) {
      try {
        let headers = new Headers()

        headers.append("Content-Type", "application/json")
        headers.append("Accept", "application/json")
        headers.append("Origin", "*")

        const response = await fetch(`${config.apiUri}/products/${idProduct}`, {
          mode: "cors",
          method: "GET",
          headers: headers,
        })
        const data = await response.json()
        let arrayInsurers = []
        for (let index = 0; index < data.length; index++) {
          arrayInsurers.push({
            id: data[index].insurerId,
            insurerName: data[index].insurerName,
            website: data[index].insurerWebsite,
          })
          this.products[indexProduct]["insurersNames"] = arrayInsurers
        }

        arrayInsurers = []
      } catch (e) {
        console.log(e)
      }
    },
    getLength() {
      return this.products.length
    },
    initialize() {
      this.products = []
    },
    getDifference(array1, array2) {
      return array1.filter(object1 => {
        return !array2.some(object2 => {
          return object1.id === object2.id
        })
      })
    },
    editItem(item) {
      this.companyAvailable = []
      this.editedIndex = this.products.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.companyAvailable = this.insurers
      this.dialogAddCompanyToProduct = true
    },

    deleteItem(item) {
      this.editedIndex = this.products.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.deleteProduct(this.editedItem.id)
      this.products.splice(this.editedIndex, 1)
      this.closeDelete()
      this.waitTimeout = true
    },

    close() {
      this.dialogAddCompanyToProduct = false
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.products[this.editedIndex], this.editedItem)
      } else {
        this.products.push(this.editedItem)
        this.post({ name: this.editedItem.name, idClientTypology: this.editedItem.idClientTypology })
      }
      this.close()
    },
  },
}
</script>
<style lang="scss" scoped>
.products {
  //background-color: #001f47;
  background-size: cover;
  padding: 5.5rem;
  margin-bottom: 20px;
}
</style>
